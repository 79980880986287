import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { DxVectorMapModule } from "devextreme-angular";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { VectorMapComponent1 } from "./vector-map/vector-map.component";
import { PictureUploadComponent } from "./picture-upload/picture-upload.component";
import { AuthNavbarComponent } from "./auth-navbar/auth-navbar.component";
import { FixedPluginComponent } from "./fixed-plugin/fixed-plugin.component";
import { DashboardNewCompaniesComponent } from './dashboard-new-companies/dashboard-new-companies.component';
import { DashboardNewFilesComponent } from './dashboard-new-files/dashboard-new-files.component';
import { DashboardMissingFilesComponent } from './dashboard-missing-files/dashboard-missing-files.component';
import { DashboardCompaniesGraphComponent } from './dashboard-companies-graph/dashboard-companies-graph.component';
import { DashboardFilesGraphComponent } from './dashboard-files-graph/dashboard-files-graph.component';
import { DashboardCompaniesCountComponent } from './dashboard-companies-count/dashboard-companies-count.component';
import { DashboardCodaCountComponent } from './dashboard-coda-count/dashboard-coda-count.component';
import { DashboardErrorsCountComponent } from './dashboard-errors-count/dashboard-errors-count.component';
import { DashboardLastUpdateComponent } from './dashboard-last-update/dashboard-last-update.component';
import { DashboardErrorsGraphComponent } from './dashboard-errors-graph/dashboard-errors-graph.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    JwBootstrapSwitchNg2Module,
    DxVectorMapModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    FooterComponent,
    VectorMapComponent1,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    FixedPluginComponent,
    DashboardNewCompaniesComponent,
    DashboardNewFilesComponent,
    DashboardMissingFilesComponent,
    DashboardCompaniesGraphComponent,
    DashboardFilesGraphComponent,
    DashboardCompaniesCountComponent,
    DashboardCodaCountComponent,
    DashboardErrorsCountComponent,
    DashboardLastUpdateComponent,
    DashboardErrorsGraphComponent
  ],
  exports: [
    FooterComponent,
    VectorMapComponent1,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    FixedPluginComponent,
    DashboardNewCompaniesComponent,
    DashboardNewFilesComponent,
    DashboardMissingFilesComponent,
    DashboardCompaniesGraphComponent,
    DashboardFilesGraphComponent,
    DashboardCompaniesCountComponent,
    DashboardCodaCountComponent,
    DashboardErrorsCountComponent,
    DashboardLastUpdateComponent,
    DashboardErrorsGraphComponent
  ]
})
export class ComponentsModule {}
