import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: "",
    redirectTo: "/app/dashboard",
    pathMatch: "full"
  },
  {
    path: "app",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren:
          "./pages/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "companies",
        loadChildren: "./pages/companies/companies.module#CompaniesModule"
      },
      {
        path: "files",
        loadChildren: "./pages/files/files.module#FilesModule"
      },
      // {
      //   path: "events",
      //   loadChildren: "./pages/events/events.module#EventsModule"
      // }
      // {
      //   path: "components",
      //   loadChildren:
      //     "./pages/examples/components/components.module#ComponentsPageModule"
      // },
      // {
      //   path: "forms",
      //   loadChildren: "./pages/examples/forms/forms.module#Forms"
      // },
      // {
      //   path: "tables",
      //   loadChildren: "./pages/examples/tables/tables.module#TablesModule"
      // },
      // {
      //   path: "maps",
      //   loadChildren: "./pages/examples/maps/maps.module#MapsModule"
      // },
      // {
      //   path: "widgets",
      //   loadChildren: "./pages/examples/widgets/widgets.module#WidgetsModule"
      // },
      // {
      //   path: "charts",
      //   loadChildren: "./pages/examples/charts/charts.module#ChartsModule"
      // },
      // {
      //   path: "calendar",
      //   loadChildren:
      //     "./pages/examples/calendar/calendar.module#CalendarModulee"
      // },
      // {
      //   path: "",
      //   loadChildren:
      //     "./pages/examples/pages/user/user-profile.module#UserModule"
      // },
      // {
      //   path: "",
      //   loadChildren:
      //     "./pages/examples/pages/timeline/timeline.module#TimelineModule"
      // },
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./pages/pages.module#PagesModule"
      }
    ]
  },
  {
    path: "",
    component: SiteLayoutComponent,
    children: [
      {
        path: "profile",
        loadChildren: "./pages/examples/pages/user/user-profile.module#UserModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "app/dashboard"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      //useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64],
      //enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
