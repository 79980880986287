import { Component, OnInit } from "@angular/core";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/app/dashboard",
    title: "Tableau de bord",
    type: "link",
    icontype: "tim-icons icon-chart-pie-36",
  },
  {
    path: "/app/companies",
    title: "Sociétés",
    type: "link",
    icontype: "tim-icons icon-book-bookmark",
  },
  // {
  //   path: "/app/companies",
  //   title: "Sociétés",
  //   type: "sub",
  //   icontype: "tim-icons icon-book-bookmark",
  //   collapse: "pages",
  //   isCollapsed: true,
  //   children: [
  //     {
  //       path: "list",
  //       title: "Liste",
  //       type: "link",
  //       smallTitle: "L"
  //     },
  //     {
  //       path: "import",
  //       title: "Import",
  //       type: "link",
  //       smallTitle: "I"
  //     }
  //   ]
  // },
  {
    path: "/app/files",
    title: "CODA",
    type: "link",
    icontype: "tim-icons icon-coins"
  },
  // {
  //   path: "/app/events",
  //   title: "Journal",
  //   type: "link",
  //   icontype: "tim-icons icon-paper"
  // }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
