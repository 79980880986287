import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { loginReducer } from './store/reducers/login.reducer';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular'; 
import { CompaniesModule } from './pages/companies/companies.module';
import { CompaniesService } from './services/companies.service';

import { ApiInterceptor } from './services/api.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { FilesService } from './services/files.service';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SiteLayoutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    StoreModule.forRoot({ login: loginReducer }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({maxAge: 25}), // Retains last 25 states
    AmplifyAngularModule,
    CompaniesModule
  ],
  providers: [
    AmplifyService,
    CompaniesService,
    FilesService,
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
