import { Component, OnInit } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-dashboard-new-files',
  templateUrl: './dashboard-new-files.component.html',
  styleUrls: ['./dashboard-new-files.component.scss']
})
export class DashboardNewFilesComponent implements OnInit {

  files: any[]

  constructor(private filesService: FilesService) { }

  ngOnInit() {
    this.filesService.getLatestFiles().subscribe(
      response => this.files = response.slice(0)
    );
  }

}
