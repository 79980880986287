import { Component, OnInit } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-dashboard-last-update',
  templateUrl: './dashboard-last-update.component.html',
  styleUrls: ['./dashboard-last-update.component.scss']
})
export class DashboardLastUpdateComponent implements OnInit {

  lastUpdate: string;

  constructor(private fileService: FilesService) { }

  ngOnInit() {
    this.fileService.getLastUpdate().subscribe(
      response => {

        const d = new Date(response);

        if (this.datesAreOnSameDay(d, new Date())) {
          this.lastUpdate = new Date(response).toLocaleTimeString("fr-BE");
        } else {
          this.lastUpdate = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
        }
      }
    );
  }

  datesAreOnSameDay(first, second) {
    return  first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate();
  }
}
