import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-errors-graph',
  templateUrl: './dashboard-errors-graph.component.html',
  styleUrls: ['./dashboard-errors-graph.component.scss']
})
export class DashboardErrorsGraphComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
