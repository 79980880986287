import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { LoginState } from '../store/states/login-state.model';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private accessToken: string;

  constructor(private store: Store<any>) {
    store.pipe(select('login')).subscribe(
      (state: LoginState) => {
        //console.log("State is " + state);
        this.accessToken = state.accessToken;
      }
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      headers: request.headers.set('x-api-key', environment.API_KEY)//.set('Authorization', this.accessToken)
    });

    console.log(request);

    return next.handle(request);

  }
}
