import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { Company, Address, BankAccount } from 'src/app/store/entities/companies/company.model';
import { CompanyState, getSelectedCompany } from 'src/app/store/entities/companies/companies.reducer';
import { UpdateCompany, AddCompanyRequest } from 'src/app/store/entities/companies/companies.actions';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {

  company: Company;
  companyEditForm: FormGroup;
  isInsert = false;
  currentEditedRow =  -1;

  constructor(private route: ActivatedRoute,
              private store: Store<CompanyState>,
              private router: Router) { }

  ngOnInit() {
    this.route.data.subscribe(
      data => {
        if (data['isInsert']) {
          this.isInsert = data.isInsert === true;
        }
      }
    );

    this.store.select(getSelectedCompany)
      .subscribe(
        company => {

          if (this.isInsert) {

            this.companyEditForm = new FormGroup({
              'name': new FormControl('', Validators.required, ),
              'accountingRef': new FormControl('', Validators.required),
              'enterpriseNumber': new FormControl('', Validators.required),
              'street': new FormControl('', Validators.required),
              'zipCode': new FormControl('', Validators.required),
              'municipality': new FormControl('', Validators.required),
              'country': new FormControl('', Validators.required)
            });
            return;
          }

          this.company = company;

          const accountingRef = this.company.accountingRef || '';
          const street = this.company.address ? this.company.address.street || '' : '';
          const zipCode = this.company.address ? this.company.address.zipCode || '' : '';
          const municipality = this.company.address ? this.company.address.municipality || '' : '';
          const country = this.company.address ? this.company.address.country || '' : '';

          if (!this.company.bankAccounts) {
            this.company.bankAccounts = [];
          }

          this.companyEditForm = new FormGroup({
              'name': new FormControl(company.name, Validators.required),
              'enterpriseNumber': new FormControl({value: company.enterpriseNumber, disabled: true}, Validators.required),
              'accountingRef': new FormControl(accountingRef, Validators.required),
              'street': new FormControl(street, Validators.required),
              'zipCode': new FormControl(zipCode, Validators.required),
              'municipality': new FormControl(municipality, Validators.required),
              'country': new FormControl(country, Validators.required)
          });
      });
  }

  submitForm() {

    if (this.companyEditForm.valid) {

      const custAddress: Address = {
        street: this.companyEditForm.value.street,
        zipCode: this.companyEditForm.value.zipCode,
        municipality: this.companyEditForm.value.municipality,
        country: this.companyEditForm.value.country,
      };

      let company: Company;

      if (this.isInsert) {
        company = {
          ...this.company,
          name: this.companyEditForm.value.name,
          enterpriseNumber: this.companyEditForm.value.enterpriseNumber,
          accountingRef: this.companyEditForm.value.accountingRef,
          source: 'Encodé',
          address: custAddress
        };
      } else {
        company = {
          ...this.company,
          name: this.companyEditForm.value.name,
          accountingRef: this.companyEditForm.value.accountingRef,
          address: custAddress
        };
      }

      if (this.isInsert) {
        this.store.dispatch(new AddCompanyRequest(company));
      } else {
        this.store.dispatch(new UpdateCompany({id: company._id, changes: company}));
      }

      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  addNewBankAccount(event: any) {
    event.preventDefault();

    const account = {
      iban: '',
      currency: 'EUR',
      createdOn: new Date()
    } as BankAccount;

    this.company.bankAccounts.push(account);
    this.currentEditedRow = this.company.bankAccounts.length - 1;
  }

  editBankAccount(index: number) {
    this.currentEditedRow = index;
  }

  deleteBankAccount(index: number) {
    this.company.bankAccounts.splice(index, 1);

    if (!this.isInsert) {
      this.store.dispatch(new UpdateCompany({id: this.company._id, changes: this.company}));
    }
  }

  updateBankAccount(index: number, account: BankAccount) {
    console.log(account);

    if (!this.isInsert) {
      this.store.dispatch(new UpdateCompany({id: this.company._id, changes: this.company}));
    }

    this.currentEditedRow = -1;
  }
}
