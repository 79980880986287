import { Routes } from "@angular/router";

import { CompaniesListComponent } from './companies-list/companies-list.component';
import { CompanyEditComponent } from './company-edit/company-edit.component';

export const CompaniesRoutes: Routes = [
  {
    path: "",
    component: CompaniesListComponent
  },
  {
    path: "details",
    component: CompaniesListComponent
  },
  {
    path: "edit",
    component: CompanyEditComponent
  },
  {
    path: "new",
    component: CompanyEditComponent,
    data: { isInsert: true }
  }
];
