import { Component, OnInit } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-dashboard-coda-count',
  templateUrl: './dashboard-coda-count.component.html',
  styleUrls: ['./dashboard-coda-count.component.scss']
})
export class DashboardCodaCountComponent implements OnInit {

  codaCount: number;

  constructor(private fileService: FilesService) { }

  ngOnInit() {
    this.fileService.getFilesCount().subscribe(
      response => this.codaCount = response
    );
  }

}
