import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { StoreModule } from '@ngrx/store';

import { CompaniesRoutes } from "./companies.routing";
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { companyReducer } from 'src/app/store/entities/companies/companies.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CompaniesEffects } from 'src/app/store/entities/companies/companies.effects';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { DataTablesModule } from "angular-datatables";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(CompaniesRoutes),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    StoreModule.forFeature('companies', companyReducer),
    EffectsModule.forFeature([CompaniesEffects]),
    DataTablesModule
  ],
  declarations: [
    CompaniesListComponent,
    CompanyEditComponent,
  ],
  exports: []
})
export class CompaniesModule {}
