import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { CompanyActionTypes, LoadCompaniesSuccess, LoadCompanies } from './companies.actions';
import { CompaniesService } from 'src/app/services/companies.service';

export class EffectError implements Action {
  type = '[ERROR] Effect error';

  constructor(public payload: any) {}
}

@Injectable()
export class CompaniesEffects {

  constructor(private store: Store<any>, private update$: Actions, private companiesService: CompaniesService) {}

  @Effect()
  getCompanies$ = this.update$.pipe(
    ofType(CompanyActionTypes.LOAD_COMPANIES),
    switchMap(action => this.companiesService.getCompanies(action['payload'])),
    map(response => {
      return new LoadCompaniesSuccess(response.data);
    })
  );

  @Effect({dispatch: false})
  updateCompany$ = this.update$.pipe(
    ofType(CompanyActionTypes.UPDATE_COMPANY),
    switchMap(action => this.companiesService.updateCompany(action)),
  );

  @Effect({dispatch: false})
  addCompany$ = this.update$.pipe(
    ofType(CompanyActionTypes.ADD_COMPANY_REQUEST),
    switchMap(action => this.companiesService.addCompany(action)),
    // map(response => {
    //   return new LoadCompanies();
    // })
  );

  @Effect({dispatch: false})
  deleteCompany$ = this.update$.pipe(
    ofType(CompanyActionTypes.DELETE_COMPANY),
    switchMap(enterpriseNumber => this.companiesService.removeCompany(enterpriseNumber)));

  @Effect({dispatch: false})
  error$ = this.update$.pipe(
    ofType('[ERROR] Effect error'),
    map(err => console.log(err))
  );

  // @Effect()
  // importCompany$ = this.update$.pipe(
  //   ofType(CompanyActionTypes.IMPORT_COMPANY),
  //   switchMap(action => this.companiesService.importCustomer(action)),
  //   map(response => new ImportCustomerConvert(response))
  // );

  // @Effect()
  // convertCustomer$ = this.update$.pipe(
  //   ofType(CustomerActionTypes.IMPORT_CUSTOMER_CONVERT),
  //   switchMap(convertAction => this.customersService.convertCompany(convertAction)),
  //   map(response => new AddCustomerRequest(response))
  // );
}
