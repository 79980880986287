import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

import { Company } from 'src/app/store/entities/companies/company.model';
import { CompanyState, selectAllCompanies } from 'src/app/store/entities/companies/companies.reducer';
import { LoadCompanies, SelectCompany, DeleteCompany } from 'src/app/store/entities/companies/companies.actions';
import { FilesService } from 'src/app/services/files.service';
import { timer, Subscription, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { CompaniesService } from "src/app/services/companies.service";
import { table } from "console";


@Component({
  selector: "app-companies-list",
  templateUrl: "companies-list.component.html"
})
export class CompaniesListComponent implements OnInit, OnDestroy, AfterViewInit {

  companies: Company[];
  timerSubscription: Subscription;
  selectSubscription: Subscription;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(private companyStore: Store<CompanyState>,
              private router: Router,
              private route: ActivatedRoute,
              private filesService: FilesService,
              private companyService: CompaniesService) {}

  ngOnInit() {
    // this.companyStore.dispatch(new LoadCompanies());

    // this.selectSubscription = this.companyStore.select(selectAllCompanies)
    //   .subscribe(
    //     companies => {
    //       this.companies = companies;
    //       this.refreshData();
    // });
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      order: [[ 0, 'asc' ]],
      columnDefs: [ 
        { "targets"  : 4,"orderable": false, "width": "5%"},
        { "targets"  : 5,"orderable": false, "width": "5%"}
      ],
      stateSave: false,
      //destroy: true,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.companyStore.dispatch(new LoadCompanies(dataTablesParameters));
        that.companyService.getCompanies(dataTablesParameters)
        .subscribe(resp => {
          that.companies = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }
    };

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        //this.ngOnDestroy();
        $('#customersTable').DataTable().ajax.reload();
      }
    });
  }

  ngOnDestroy() {
    // this.timerSubscription.unsubscribe();
    // this.selectSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.on( 'draw.dt', function () {
      if($('.dataTables_empty').length > 0)
      {
        $('.dataTables_empty').remove();
      }
    });
  
    });

    //this.refreshData();
  }

  // refreshData() {
  //   this.timerSubscription = timer(5000)
  //     .subscribe(
  //       () => {
  //         this.companyStore.dispatch(new LoadCompanies());
  //       }
  //     );
  // }


  // selectCompany(company: Company) {
  //   this.companyStore.dispatch(new SelectCompany(company._id));
  //   this.router.navigate(['details'], { relativeTo: this.route });
  // }

  deleteCompany(company: Company) {
    this.companyStore.dispatch(new DeleteCompany(company._id));
  }

  editCompany(company: Company) {
    this.companyStore.dispatch(new SelectCompany(company._id));
    this.router.navigate(['edit'], { relativeTo: this.route });
  }

  addCompany(event: any) {
    event.preventDefault();
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  refreshFiles(event: any) {
    event.preventDefault();
    this.filesService.processWaitingFiles().subscribe(
      () => {
        console.log('Files refresh complete');
      }
    );
  }
}
