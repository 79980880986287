// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': 'eu-west-1',
    'aws_cognito_identity_pool_id': 'eu-west-1:6d052d6c-48af-47b3-90d6-f62ab56374fb',
    'aws_cognito_region': 'eu-west-1',
    'aws_user_pools_id': 'eu-west-1_4aTh0tRR8',
    'aws_user_pools_web_client_id': '4et824s4il5ef5h2llf71eksfj',
    'oauth': {}
};


export default awsmobile;