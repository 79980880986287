import { Action } from '@ngrx/store';

import { ActionTypes } from '../actions/login.actions';
import { LoginState } from '../states/login-state.model';

export const initialState: LoginState = {
  isLoggedIn: false,
  accessToken: undefined
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.Login:
      return { isLoggedIn: true, accessToken: action.payload };
    case ActionTypes.Logout:
      return { isLoggedIn: false, accessToken: undefined };
    default:
      return state;
  }
}