import { Component, OnInit } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { Company } from 'src/app/store/entities/companies/company.model';

@Component({
  selector: 'app-dashboard-new-companies',
  templateUrl: './dashboard-new-companies.component.html',
  styleUrls: ['./dashboard-new-companies.component.scss']
})
export class DashboardNewCompaniesComponent implements OnInit {

  companies: Company[];

  constructor(private companiesService: CompaniesService) { }

  ngOnInit() {
    this.companiesService.getLatestCompanies().subscribe(
      response => this.companies = response.slice(0)
    );
  }

}
