import { Component, OnInit } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';

@Component({
  selector: 'app-dashboard-companies-count',
  templateUrl: './dashboard-companies-count.component.html',
  styleUrls: ['./dashboard-companies-count.component.scss']
})
export class DashboardCompaniesCountComponent implements OnInit {

  companiesCount: number;

  constructor(private companiesService: CompaniesService) { }

  ngOnInit() {
    this.companiesService.getCompaniesCount().subscribe(
      response => this.companiesCount = response
    );
  }

}
