import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector, State } from '@ngrx/store';

import { Company  } from './company.model';
import { CompanyActionTypes } from './companies.actions';

export interface CompanyState extends EntityState<Company> {
  selectedCompanyId: string | null;
}

const companyAdapter = createEntityAdapter<Company>({
  selectId: (company: Company) => company._id
});

const companyInitialState: CompanyState = companyAdapter.getInitialState({
  selectedCompanyId: null
});

export function companyReducer(state: CompanyState = companyInitialState, action) {
  switch (action.type) {
    case CompanyActionTypes.LOAD_COMPANIES:
      return state;
    case CompanyActionTypes.LOAD_COMPANIES_SUCCESS:
      return companyAdapter.addAll(action.payload, state);
    case CompanyActionTypes.DELETE_COMPANY:
      return companyAdapter.removeOne(action.payload, state);
    case CompanyActionTypes.SELECT_COMPANY:
      return { ...state, selectedCompanyId: action.payload };
    case CompanyActionTypes.UPDATE_COMPANY:
      //return companyAdapter.updateOne(action.payload, state);
      return state;
    case CompanyActionTypes.ADD_COMPANY_REQUEST:
      return state;
    case CompanyActionTypes.ADD_COMPANY_SUCCESS:
      //return companyAdapter.addOne(action.payload, state);
      return state;
    // case CompanyActionTypes.IMPORT_COMPANY:
    //   return state;
    //case CompanyActionTypes.IMPORT_COMPANY_CONVERT:
    //  return state;
    default:
      return state;
  }
}

export const selectCompanyState = createFeatureSelector<CompanyState>('companies');

export const { selectAll: selectAllCompanies } = companyAdapter.getSelectors(selectCompanyState);

export const getSelectedCompany = createSelector(
  selectCompanyState,
  (state) => {
    return state.entities[state.selectedCompanyId];
  }
);
