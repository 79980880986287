import { Component, OnInit } from '@angular/core';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-dashboard-errors-count',
  templateUrl: './dashboard-errors-count.component.html',
  styleUrls: ['./dashboard-errors-count.component.scss']
})
export class DashboardErrorsCountComponent implements OnInit {

  errorsCount: number;

  constructor(private filesService: FilesService) { }

  ngOnInit() {
    this.filesService.getFilesErorsCount().subscribe(
      response => this.errorsCount = response
    );
  }

}
