import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-missing-files',
  templateUrl: './dashboard-missing-files.component.html',
  styleUrls: ['./dashboard-missing-files.component.scss']
})
export class DashboardMissingFilesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
