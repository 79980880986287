import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Company } from './company.model';

export enum CompanyActionTypes {
  LOAD_COMPANIES = '[Company] Load companies',
  LOAD_COMPANIES_SUCCESS = '[Company] Load companies success',
  DELETE_COMPANY = '[Company] Delete company',
  SELECT_COMPANY = '[Company] Select company',
  UPDATE_COMPANY = '[Company] Update company',
  ADD_COMPANY_REQUEST = '[Company] Add company request',
  ADD_COMPANY_SUCCESS = '[Company] Add company success',
  IMPORT_COMPANY = '[Company] Import company',
//  IMPORT_COMPANY_CONVERT = '[Company] Import company convert'
}

export class LoadCompanies implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANIES;

  constructor(public payload: any) {}
}

export class LoadCompaniesSuccess implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANIES_SUCCESS;

  constructor(public payload: Company[]) {}
}

export class DeleteCompany implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY;

  constructor(public payload: string) {}
}

export class SelectCompany implements Action {
  readonly type = CompanyActionTypes.SELECT_COMPANY;

  constructor(public payload: string) {}
}

export class UpdateCompany implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY;

  constructor(public payload: Update<Company>) {}
}

export class AddCompanyRequest implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_REQUEST;

  constructor(public payload: Company) {}
}

export class AddCompanySuccess implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_SUCCESS;

  constructor(public payload: Company) {}
}

// export class ImportCompany implements Action {
//   readonly type = CompanyActionTypes.IMPORT_COMPANY;

//   constructor(public payload: string) {}
// }

// export class ImportCompanyConvert implements Action {
//   readonly type = CompanyActionTypes.IMPORT_COMPANY_CONVERT;

//   constructor(public payload: Company[]) {}
// }

export type CompanyActionsUnion =
  | LoadCompanies
  | LoadCompaniesSuccess
  | DeleteCompany
  | UpdateCompany
  | AddCompanyRequest
  | AddCompanySuccess;
//  | ImportCompany
//  | ImportCompanyConvert;
