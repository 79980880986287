import { Action } from '@ngrx/store';

export enum ActionTypes {
  Login = '[Login component] Login',
  Logout = '[Login component] Logout'
}

export class LoginAction implements Action {
  readonly type = ActionTypes.Login;

  constructor(public payload: string) {}
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.Logout;
}